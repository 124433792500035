.mapboxgl-popup {
  max-width: 250px;
}

.mapboxgl-popup-content {
  max-width: 100%;
  border-radius: 20px;
  border-style: solid;
  border-color: #007000;
}

.mapboxgl-popup-close-button {
  font-size: x-large;
}
