#holder-codes-table, .holder-codes-th, .holder-codes-td {
  border: 1px solid black;
  border-collapse: collapse;
}

.holder-codes-tr:nth-child(even) {background-color: #f2f2f2;}

.holder-codes-th, .holder-codes-td {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
