.area-popup-header {
  text-align: center;
}

.area-popup-subheader {
  text-align: 'left';
}

.area-about-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}

.area-about-tr {
  vertical-align: center;
}
.area-about-tr:nth-child(even) {background-color: #f2f2f2;}

.area-about-td {
  padding-left: 5px;
  padding-right: 10px;
  padding-bottom: 3px;
  padding-top: 3px;
  text-align: left;
  word-wrap: break-word;
}

.area-about-td:first-child {
  font-weight: bold;
}

.blue-link-with-no-decor {
  color: blue;
  text-decoration: none;
}
