.navbar {
  z-index: 1;
  height: 7vh;
  background-color: #ffffff;
}

.navbar-brand {
  margin: 10px;
}

.container {
  margin-left: 0 !important;
}

.map-navbar-form {
  padding-right: 0 !important;
}

.form-label {
  margin-bottom: 0 !important;
}

.form-select {
  padding-top: 0.1rem !important;
  padding-bottom: 0.1rem !important;
}

